import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {catchError, EMPTY, Observable, tap, timeout, TimeoutError} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {IResponseDto} from "../../models/exceptions/response-dto";
import {SnackbarService} from "../services/snackbar.service";
import {LoadingService} from "../services/loading.service";
import {MessageErrorResolver} from "../../utilities/message-error-resolver";
import {Router} from "@angular/router";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private snackbarService: SnackbarService, private loadingService: LoadingService, private messageErrorResolver: MessageErrorResolver, private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.authService.getAccessTokenIfValidated();
    if (accessToken) request = request.clone({
      headers: request.headers.set('authorization', `Bearer ${this.authService.getAccessToken()}`)
    });
    // console.log("📧 REQUEST:", request);
    return next.handle(request).pipe(
      timeout(250000),
      catchError((error: HttpErrorResponse) => this.resolveAndTransferError(error)),
      tap(httpEvent => this.log(httpEvent))
    );
  }

  private log(httpEvent: HttpEvent<unknown>): void {
    if (httpEvent instanceof HttpResponse) {
      // console.log("📩 RESPONSE:", httpEvent);
    }
  }

  private resolveAndTransferError(error: HttpErrorResponse): Observable<never> {
    if (error instanceof TimeoutError) {
      this.loadingService.deactivate();
      this.snackbarService.showWarn("Não conseguimos obter uma resposta do servidor em tempo hábil. Verifique se sua conexão com a internet está estável e tente novamente.")
      return EMPTY;
    }

    if (error.status === 401) {
      this.authService.clearCredentials();
      this.loadingService.deactivate();
      this.messageErrorResolver.resolveMessage(error.error as IResponseDto<Error>);
      return EMPTY;
    }

    if (error.status === 403) {
      const accessToken = this.authService.getAccessTokenIfValidated();
      this.loadingService.deactivate();
      if (accessToken === null) {
        this.authService.clearCredentials();
        this.router.navigate(['/']);
      } else {
        const responseMessageError = (error.error as IResponseDto<Error>).response.message
        if (responseMessageError) {
          this.snackbarService.showDanger(responseMessageError);
        } else {
          this.snackbarService.showDanger("Permissão negada para esta operação.");
        }
      }
      return EMPTY;
    }

    if (error.status === 500) {
      this.snackbarService.showDanger("Ocorreu um erro inesperado!");
      this.loadingService.deactivate();
      return EMPTY;
    }

    if (error.status === 0) {
      this.loadingService.deactivate();
      this.snackbarService.showDanger("Não foi possível se conectar com o servidor. Contate um administrador do sistema ou tente novamente mais tarde.")
      return EMPTY;
    }

    throw error.error as IResponseDto<Error>;
  }

}

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppInterceptor,
    multi: true,
  }
];
