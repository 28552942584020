import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sigecom-message',
  template: `
    <article class="w-full overflow-hidden rounded-md">
      <div class="py-3 px-4 font-bold" [style.backgroundColor]="typeColorsMap[type].bgHeader" [style.color]="typeColorsMap[type].textHeader">
        <ng-content select="[header]"/>
      </div>
      <div class="px-6 py-5" [style.backgroundColor]="typeColorsMap[type].bgBody" [style.color]="typeColorsMap[type].textBody">
        <ng-content select="[body]"/>
      </div>
    </article>
  `,
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() type: MessageType = 'warn';

  public typeColorsMap: { [key in MessageType]: any } = {
    success: {
      bgHeader: '#47c68d',
      textHeader: '#fff',
      bgBody: '#EFFAF5',
      textBody: '#257953',
    },
    danger: {
      bgHeader: '#f14668',
      textHeader: '#fff',
      bgBody: '#FEECF0',
      textBody: '#cc0f35',
    },
    warn: {
      bgHeader: '#ffdf89',
      textHeader: '#000000b3',
      bgBody: '#FFFAEB',
      textBody: '#946c00',
    },
    info: {
      bgHeader: '#3E8ED0',
      textHeader: '#fff',
      bgBody: '#EFF5FB',
      textBody: '#296fa8',
    },
  };
  
  ngOnInit() { }
}

export type MessageType = 'success' | 'danger' | 'warn' | 'info';

/* 
          pri     bg     text
sucess: #47c68d #EFFAF5 #257953
warn:   #ffdf89 #FFFAEB #946c00 #000000b3
info:   #3E8ED0 #EFF5FB #296fa8
danger: #f14668 #FEECF0 #cc0f35

*/