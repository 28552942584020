import { Injectable } from '@angular/core';
import { SnackbarType } from '@shared/components/snackbar/snackbar.component';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private _snackBarProps = new BehaviorSubject<SnackbarProps[]>([]);
  snackBarProps$ = this._snackBarProps.asObservable();

  showSuccess(message: string, isHtml: boolean = false) {
    this.addMessage({ message, type: SnackbarType.success, isHtml });
  }

  showDanger(message: string, isHtml: boolean = false) {
    this.addMessage({ message, type: SnackbarType.danger, isHtml });
  }

  showWarn(message: string, isHtml: boolean = false) {
    this.addMessage({ message, type: SnackbarType.warn, isHtml });
  }

  showInfo(message: string, isHtml: boolean = false) {
    this.addMessage({ message, type: SnackbarType.info, isHtml });
  }

  private addMessage(props: SnackbarProps) {
    const currentProps = this._snackBarProps.value;
    this._snackBarProps.next([...currentProps, props]);
  }

  removeMessage(index: number) {
    const currentProps = this._snackBarProps.value;
    currentProps.splice(index, 1);
    this._snackBarProps.next([...currentProps]);
  }
}

export interface SnackbarProps {
  message: string;
  type?: SnackbarType;
  isHtml?: boolean;
}
