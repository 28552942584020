import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { StepComponent } from './step/step.component';

@Component({
  selector: 'sigecom-stepper',
  template: `
    <div class="flex justify-between">
      <sigecom-button title="Anterior" icon="angle-left" (buttonClicked)="prev()" [disabled]="isFirstStep()" />
        <div class="mx-auto w-full flex justify-center items-center">
          <ng-container *ngFor="let step of steps; let last = last; let index = index;">
            <button
              type="button"
              class="rounded-full flex items-center border-[6px] justify-center text-lg w-12 h-12 transition-all delay-75 ease-in-out duration-500"
              [ngClass]="{
                'bg-sigecom-info border-[#E8E8E8] text-white': _currentStep === index,
                'bg-transparent border-transparent text-[#3D3D3D]': !(_currentStep === index),
                'opacity-50 cursor-not-allowed': isStepDisabled(index)
              }"
              (click)="changeStepActive(index)"
              [disabled]="isStepDisabled(index)"
            >
              <fa-icon [icon]="step.icon"/>
            </button>

            <div *ngIf="!last" class="h-1 w-[5rem] rounded-full text-center bg-[#D7CECE]"></div>
          </ng-container>
        </div>
      <sigecom-button title="Proxímo" icon="angle-right" (buttonClicked)="next()" [disabled]="isLastStep()"/>
    </div>

    <div class="my-5">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements AfterViewInit{

  @ContentChildren(StepComponent) steps!: QueryList<StepComponent>;

  _currentStep: number = 0;

  @Input() set currentStep(index: number) {
    this._currentStep = index;
    this.changeStepActive(index);
  };
  @Output() currentStepChange = new EventEmitter<number>();

  @Input() nextDisabled = false;
  @Input() prevDisabled = false;

  @Input() disabledSteps: number[] = [];

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.changeStepActive(this._currentStep);
    });
  }

  next() {
    if (this.isLastStep()) return;

    const nextStep = this._currentStep + 1;
    if (nextStep <= (this.steps.length - 1) && !this.isStepDisabled(nextStep)) {
      this._currentStep++;
      this.changeStepActive(this._currentStep);
      this.currentStepChange.emit(this._currentStep);
    }
  }

  prev() {
    if (this.isFirstStep()) return;

    const prevStep = this._currentStep - 1;
    if (prevStep >= 0 && !this.isStepDisabled(prevStep)) {
      this._currentStep--;
      this.changeStepActive(this._currentStep);
      this.currentStepChange.emit(this._currentStep);
    }
  }

  isLastStep(): boolean {
    return this._currentStep === this.steps.length - 1;
  }

  isFirstStep(): boolean {
    return this._currentStep === 0;
  }

  changeStepActive(index: number) {
    if (this.isStepDisabled(index)) return;

    this._currentStep = index;
    this.steps?.forEach((step, i) => {
      step.active = i === index;
    });
  }

  isStepDisabled(index: number): boolean {
    return this.disabledSteps.includes(index);
  }
}
