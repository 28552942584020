import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from '@angular/core';

@Component({
  selector: 'sigecom-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent<T extends Record<string, any>> implements OnInit, OnChanges {
  public columns: string[] = [];
  public rows: T[] = [];

  @Input() columnMapping!: Record<string, any>;
  @Input() data!: T[];
  @Input() totalRecords?: number;

  @Input() extraButtonLabel: string | undefined;
  @Input() extraButtonLabel2: string | undefined;
  @Input() extraButtonLabel3: string | undefined;
  @Input() extraButtonLabel4: string | undefined;

  @Output() editEvent: EventEmitter<T> = new EventEmitter<T>();
  @Output() deleteEvent: EventEmitter<T> = new EventEmitter<T>();
  @Output() extraEvent: EventEmitter<T> = new EventEmitter<T>();
  @Output() extraEvent2: EventEmitter<T> = new EventEmitter<T>();
  @Output() extraEvent3: EventEmitter<T> = new EventEmitter<T>();
  @Output() extraEvent4: EventEmitter<T> = new EventEmitter<T>();

  public ngOnInit(): void {
    this.columns = Object.keys(this.columnMapping) as string[];
    this.rows = this.data;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.columns = Object.keys(this.columnMapping);
      this.rows = this.data;
    }
  }

  public triggerEditMethod(entity: T): void {
    this.editEvent.emit(entity);
  }

  public triggerDeleteMethod(entityId: T): void {
    this.deleteEvent.emit(entityId);
  }

  public triggerExtraMethod(entityId: T): void {
    this.extraEvent.emit(entityId);
  }

  public triggerExtraMethod2(entityId: T): void {
    this.extraEvent2.emit(entityId);
  }

  public triggerExtraMethod3(entityId: T): void {
    this.extraEvent3.emit(entityId);
  }

  public triggerExtraMethod4(entityId: T): void {
    this.extraEvent4.emit(entityId);
  }

  public mapDataForRespectiveColumn(column: string, row: T) {
    const attributePath = this.columnMapping[column];
    const attributes = attributePath.split('.');

    let value = row;
    for (const attribute of attributes) {
      value = value[attribute];
    }
    return value;
  }

  public hasExtraButtons(): number {
    let displacementXAxis = 100
    if (!!this.extraButtonLabel || !!this.extraButtonLabel2 || !!this.extraButtonLabel3 || !!this.extraButtonLabel4) {
      displacementXAxis = 350
    }
    return displacementXAxis;
  }

}
