import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sigecom-action-buttons',
  template: `
  <div #sentinel></div>
  <div
    [ngClass]="{
      'sticky bottom-10 shadow-md z-50 shadow-sigecom-gray border-2 border-sigecom-gray-1 rounded-lg': isFloating,
      'relative': !isFloating,
    }"
    class="flex gap-2 justify-end p-4 bg-white"
  >
    <sigecom-button
      type="danger"
      [outlined]="true"
      [title]="cancelTitle"
      [icon]="cancelIcon"
      (buttonClicked)="onCancelClick()"
    >
    </sigecom-button>

    <sigecom-button
      type="info"
      [outlined]="true"
      [title]="saveTitle"
      [icon]="saveIcon"
      [disabled]="isSaveDisabled"
      [submitButton]="true"
      (buttonClicked)="onSaveClick()"
    >
    </sigecom-button>
  </div>
  `,
})
export class ActionButtonsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() cancelTitle: string = 'Cancelar';
  @Input() saveTitle: string = 'Salvar';
  @Input() cancelIcon: IconName = 'xmark';
  @Input() saveIcon: IconName = 'check';

  @Input() isSaveDisabled: boolean = false;

  @Input() disableFloating: boolean = false;

  @Output() cancelClick: EventEmitter<void> = new EventEmitter();
  @Output() saveClick: EventEmitter<void> = new EventEmitter();

  isFloating: boolean = false;
  observer?: IntersectionObserver
  @ViewChild('sentinel') sentinel!: ElementRef;

  constructor(private el: ElementRef) {}

  ngOnInit() {
  }

  ngAfterViewInit() {

    if (this.disableFloating) {
      this.isFloating = false;
      return;
    }

    this.observer = new IntersectionObserver(
      ([entry]) => {
        console.log('Sentinel isIntersecting:', entry.isIntersecting);
        this.isFloating = !entry.isIntersecting;
      },
      {
        root: null, 
        threshold: 0, 
      }
    );

    if (this.sentinel && this.sentinel.nativeElement) {
      this.observer.observe(this.sentinel.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onCancelClick() {
    this.cancelClick.emit();
  }

  onSaveClick() {
    this.saveClick.emit();
  }
}
