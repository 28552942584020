import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sigecom-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class PaginationListComponent implements OnInit, OnChanges {

  @Input() totalPages: number = 0;
  @Input() maxDisplayedPages: number = 0;
  
  @Output() pageChange = new EventEmitter<number>();
  
  public page: number = 0;
  public displayedPages: number[] = [];
  public lastDisplayedPage = 0;

  constructor() {}

  public ngOnInit(): void {
    this.displayListOfPages();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalPages'] && changes['totalPages'].currentValue) {
      this.resetPagination();
      this.displayListOfPages();
    }
  }

 public displayListOfPages() {
    const lastLenghtOfdisplayedPages = this.totalPages % this.maxDisplayedPages;

    this.displayedPages = Array.from(
      {
        length: this.lastDisplayedPage + this.maxDisplayedPages > this.totalPages
          ? lastLenghtOfdisplayedPages 
          : this.maxDisplayedPages 
      },
      (_, i) => this.lastDisplayedPage + i + 1);

    return this.displayedPages;
  }

  public prevPage(): void {
    if (this.lastDisplayedPage > this.maxDisplayedPages) {
      this.lastDisplayedPage -= this.maxDisplayedPages;
    } else {
      this.lastDisplayedPage = 0;
    }
  
    this.displayListOfPages();
  }

  public nextPage(): void {    
    if (this.lastDisplayedPage < (this.totalPages - this.maxDisplayedPages)) {
      this.lastDisplayedPage = this.displayedPages[this.displayedPages.length - 1];
      
      this.displayListOfPages();
    }
	}

  public resetPagination(): void {
    this.lastDisplayedPage = 0;
    this.page = 0;
  }

  public goToPage(page: number): void {
    this.page = page;
		this.pageChange.emit(page);
	}

  get currentPageLabel(): number {
		return this.page + 1;
	}

}
