import { Component, Input, } from '@angular/core';

declare let $:any;
@Component({
  selector: 'sigecom-panel-box',
  template: `
    <div class="panel-box">
      <div style="width: 100%; height: 4px;" [ngStyle]="{'background-color': colorHeader }"></div>
      <div class="panel-box-header with-border" *ngIf="showHeader">
          <h3 class="box-title">{{title}}</h3>
       <div class="box-tools">
        <ng-content select="[tools]"></ng-content>
       </div>
      </div>
      <div class="panel-box-body">
        <ng-content select="[content]"></ng-content>
      </div>
      <div class="panel-box-footer with-border" *ngIf="showFooter">
        <div style="font-size: 1.5em;">
          <ng-content select="[footer]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./panel-box.component.scss'],
})
export class PanelBoxComponent {

  @Input() title?: string;
  @Input() colorHeader?: string = "#012169";
  @Input() showHeader?: boolean = true;
  @Input() showFooter?: boolean = true;

  constructor() {
  }
}
