import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-not-found-message-list',
  template: `
    <div class="w-full p-6 bg-white shadow-md rounded-md mt-12">
      <div class="column">{{ message }}</div>
    </div>
  `
})
export class NotFoundMessageListComponent {
  @Input() message = "Não encontrado"
}
