<div class="px-0 pt-6 flex flex-col bg-white h-full static" id="sidenav">


 <sigecom-marquee *ngIf="enable"/>

  <div *ngIf="!hideSideNav" class="w-full flex justify-center mt-4 mb-10">
    <img (click)="redirectTo();" class="cursor-pointer" src="assets/logo/LogoCBDEDark.png"
      alt="Imagem da Confederação Brasileira de Desporto Escolar" width="135" height="60">
  </div>

  <ul class="flex-1 flex flex-col overflow-y-auto" id="style1">

    <li class="text-sigecom-gray font-bold uppercase text-sm cursor-pointer" *ngFor="let menuItem of menuItens">

      <!-- Menu item -->
      <a title="{{menuItem.title}}"
        class="overflow-hidden w-full flex items-center gap-2 justify-{{!hideSideNav ? 'between' : 'center'}} px-4 py-2"
        (click)="activeMenu(menuItem)">

        <div class="flex items-center gap-2" *ngIf="!hideSideNav">
          <fa-icon [icon]="menuItem.isOpen() ? 'chevron-down' : 'chevron-right'"
            *ngIf="menuItem.subitens.length && !hideSideNav"></fa-icon>
          <span *ngIf="!hideSideNav">{{menuItem.title}}</span>
        </div>

        <fa-icon [icon]="menuItem.icon"></fa-icon>
      </a>

      <!-- Menu subitems -->
      <ul [@OpenMenu]="'flyIn'"  *ngIf="menuItem.isOpen()">
        <li *ngFor="let subitem of menuItem.subitens">

          <a
            title="{{subitem.title}}"
            class="overflow-hidden w-full px-4 py-2.5 flex items-center gap-2 justify-{{!hideSideNav ? 'between pl-9' : 'center'}} text-xs"
            (click)="activeMenu(subitem)"
            [routerLink]="subitem.route"
            routerLinkActive="bg-gray-200"
            [routerLinkActiveOptions]="{exact: true}"
          >

            <div class="flex items-center gap-2" *ngIf="!hideSideNav">
              <!-- <fa-icon [icon]="'arrow-right'"></fa-icon> -->
              <span>{{subitem.title}}</span>
            </div>

            <fa-icon [icon]="subitem.icon"></fa-icon>
          </a>

        </li>
      </ul>

    </li>
  </ul>

  <div class="text-center p-2">
    <small>v{{ environment.versionNumber }} {{ environment.versionTag }}</small>
  </div>
</div>