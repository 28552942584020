import { Component } from '@angular/core';

@Component({
  selector: 'sigecom-list-container',
  template: `
    <div class="w-full overflow-y-auto flex flex-col gap-2 p-1">
      <ng-content></ng-content>
    </div>
  `,
})
export class ListContainerComponent {}
