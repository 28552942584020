import { ButtonType } from './../button/button.component';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationModalService, ConfirmationModalType, IConfirmationModal } from '../../services/confirmation-modal.service';

@Component({
  selector: 'sigecom-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public args: IConfirmationModal | null = null;

  private open: boolean = true;

  protected readonly modalType = ConfirmationModalType;

  @Input() header?: string;

  constructor(private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    this.confirmationModalService.modalOpen$.subscribe((modalIsOpen) => {
      this.open = modalIsOpen;
    });

    this.confirmationModalService.modalArgs$.subscribe((args) => {
      this.args = args;
    });
  }

  confirm(): void {
    if (this.args) {
      this.args.callbackExecuteAction();
    }
    this.endAction();
  }

  cancel(): void {
    this.endAction();
  }

  endAction() {
    this.confirmationModalService.closeModal();
    this.confirmationModalService.clearArgs();
  }

  getButtonType(): ButtonType {
    if (this.args) {
      switch (this.args?.modalType) {
        case ConfirmationModalType.success:
          return 'success';
        case ConfirmationModalType.danger:
          return 'danger';
        case ConfirmationModalType.warn:
          return 'warn';
        case ConfirmationModalType.info:
          return 'info';
      }
    }
    return 'default';
  }

  get isOpen(): boolean { return this.open; }
}
