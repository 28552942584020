<sigecom-content-container title="Downloads">
  <div content>
    <h2 class="block mb-4 text-sigecom-outlined-gray font-bold">
      Aplicações
    </h2>
    <div class="grid grid-cols-5 gap-5">
      <div class="h-60 flex flex-col items-center justify-center shadow-flat-2xl rounded-2xl hover:ring-2 hover:ring-blue-600 hover:-translate-y-1 hover:scale-100 transition-all ease-in-out duration-300">
        <a
          class=" block h-40 w-40 bg-app-scan bg-cover"
          [href]="qrCodeAppUrl"
          title="Baixar aplicação de leitura"
        ></a>
        <p class="p-1 mt-2 text-center">Leitor de QRCode</p>
      </div>
      <div class="h-60 flex flex-col items-center justify-center shadow-flat-2xl rounded-2xl hover:ring-2 hover:ring-blue-600 hover:-translate-y-1 hover:scale-100 transition-all ease-in-out duration-300">
        <a
          class=" block h-40 w-40 bg-app-pro bg-cover"
          href="https://cbdeprontuarios.mms.inf.br/"
          target="_blank"
          title="Baixar aplicação de leitura"
        ></a>
        <p class="p-1 mt-2 text-center">Prontuários</p>
      </div>
    </div>
  </div>
</sigecom-content-container>

