import {Component, Input} from '@angular/core';

@Component({
  selector: 'sigecom-content-container',
  template: `
    <div class="h-full p-12">

      <div class="flex justify-between mb-4">
        <h2 *ngIf="title" class="text-sigecom-gray text-2xl font-semibold">{{ title }}</h2>

        <div>
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>

      <div class="p-6 rounded-md shadow-md bg-white">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent {
  @Input() title?: string;
  @Input() topGap: number = 3;
  @Input() bottomGap: number = 3;
  @Input() horizontalGap: number = 3;
}
