import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-list-info',
  template: `
    <p class="text-sigecom-gray text-sm">
      <span class="font-bold">{{ info }}: </span>
      <span class="font-medium">{{ value }}</span>
    </p>
  `,
  styles: [
  ]
})
export class ListInfoComponent {
  @Input() info: string = '';
  @Input() value: string = '';
}
