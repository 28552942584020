import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'sigecom-step',
  template: `
    <div *ngIf="active" class="p-2">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./step.component.scss']
})
export class StepComponent {
  @Input({ required: true }) icon!: IconProp;
  @Input() active: boolean = false;
}
