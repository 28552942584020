<nav class="w-full" role="navigation" aria-label="pagination">
  <ul class="w-full inline-flex -space-x-px text-sm justify-center h-10">
    <li>
      <a
        class="flex items-center justify-center px-3 h-10 ms-0 leading-tight text-gray-500
        bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-200 cursor-pointer"
        (click)="prevPage()"
        [ngClass]="{ 'is-static': lastDisplayedPage < maxDisplayedPages }"
      >
          Anterior
      </a>
    </li>

    <li *ngFor="let pageNumber of displayedPages" (click)="goToPage(pageNumber - 1)">

      <a class="flex items-center justify-center px-3 h-10 leading-tight border border-gray-300 cursor-pointer"
        [ngClass]="{
          'bg-white text-sigecom-gray hover:bg-gray-200': !(pageNumber === currentPageLabel),
          'bg-cbde-dark-blue text-white font-bold': pageNumber === currentPageLabel
        }"
      >
        {{ pageNumber }}
      </a>

    </li>

    <li>
      <a
        class="flex items-center justify-center px-3 h-10 ms-0 leading-tight text-gray-500
        bg-white border border-e-0 border-gray-300 rounded-e-lg hover:bg-gray-200 cursor-pointer"
        (click)="nextPage()"
        [ngClass]="{ 'is-static': !(lastDisplayedPage < (totalPages - maxDisplayedPages))}"
      >
          Próximo
      </a>
    </li>
  </ul>
</nav>
