import {inject, NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {GeneralGuard} from "./core/shared/guard/general-guard.service";
import { DownloadsComponent } from '@modules/downloads/downloads.component';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/dashboards/dashboard.module').then(
        (mod) => mod.DashboardModule
      ),
  },
  {
    path: 'login',
    canActivate: [() => inject(GeneralGuard).ifAuthenticatedBlock()],
    loadChildren: () =>
      import('./core/modules/login/login.module').then(
        (mod) => mod.LoginModule
      ),
  },
  {
    path: 'eventos',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/events/events.module').then(
        (mod) => mod.EventsModule
      ),
  },
  {
    path: 'federacoes',
    canActivate: [() => inject(GeneralGuard).isCbdeAdmin()],
    loadChildren: () =>
      import('./core/modules/federation/federation.module').then(
        (mod) => mod.FederationModule
      ),
  },
  {
    path: 'competicoes',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/championship/championship.module').then(
        (mod) => mod.ChampionshipModule
      ),
  },
  {
    path: 'inscricoes',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/enrollments/enrollments.module').then(
        (mod) => mod.EnrollmentsModule
      ),
  },
  {
    path: 'perfil',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/profile/profile.module').then(
        (mod) => mod.ProfileModule
      ),
  },
  {
    path: 'usuarios',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import('./core/modules/user/user.module').then((mod) => mod.UserModule),
  },
  {
    path: 'instituicoes',
    canActivate: [() => inject(GeneralGuard).isAuthenticated()],
    loadChildren: () =>
      import(
        './core/modules/teaching-institution/teaching-institution.module'
        ).then((mod) => mod.TeachingInstitutionModule)
  },
  {
    path: 'funcoes',
    canActivate: [() => inject(GeneralGuard).isCbdeAdmin()],
    loadChildren: () =>
      import('./core/modules/functions/functions.module').then((mod) => mod.FunctionsModule)
  },
  {
    path: 'competicoes',
    canActivate: [() => inject(GeneralGuard).isAuthenticated],
    loadChildren: () =>
      import('./core/modules/championship/championship.module').then((mod) => mod.ChampionshipModule)
  },
  {
    path: 'beneficios',
    canActivate: [() => inject(GeneralGuard).isAuthenticated],
    loadChildren: () =>
      import('./core/modules/benefits/benefit.module').then((mod) => mod.BenefitModule)
  },
  {
    path: 'servicos',
    canActivate: [() => inject(GeneralGuard).isAuthenticated],
    loadChildren: () =>
      import('./core/modules/services/services.module').then((mod) => mod.ServicesModule)
  },
  {
    path: 'registros',
    canActivate: [() => inject(GeneralGuard).isAuthenticated],
    loadChildren: () =>
      import('./core/modules/records/records.module').then((mod) => mod.RecordsModule)
  },
  {
    path: 'exportacoes',
    canActivate: [() => inject(GeneralGuard).isCbdeAdmin],
    loadChildren: () =>
      import('./core/modules/reports/reports.module').then((mod) => mod.ReportsModule)
  },
  {
    path: 'downloads',
    canActivate: [() => inject(GeneralGuard).isAuthenticated],
    component: DownloadsComponent
  },
  {
    path: 'app',
    canActivate: [],
    loadChildren: () =>
      import('./core/external/external.module').then((mod) => mod.ExternalModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/usuarios'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
