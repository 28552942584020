import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, booleanAttribute } from '@angular/core';

@Component({
  selector: 'sigecom-modal',
  template: `
    <div class="bg-sigecom-modal-dark fixed inset-0 w-full h-full z-[120] flex justify-center items-center" *ngIf="open" (click)="clickOutside ? toggleModal() : null;">
      <div
        class="w-auto min-w-[5rem] min-h-[5rem] p-5 pr-7 overflow-x-hidden overflow-y-auto max-h-[80%] max-w-5xl absolute flex flex-col bg-white rounded-md animate-open-modal"
        (click)="$event.stopPropagation()"
      >
        <fa-icon icon="xmark" (click)="toggleModal()" class="absolute right-4 top-4 text-xl cursor-pointer" *ngIf="showCloseButton"></fa-icon>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  // TODO:
  // - ajustar componente para comportar conteúdo dinâmico
  // - customizar header, content, e footer e torna-los opcionais

  @Input() open: boolean = false;
  @Input({ transform: booleanAttribute }) clickOutside: boolean = false;
  @Output() modalStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showCloseButton: boolean = true;

  constructor() { }

  toggleModal() {
    this.open = !this.open;
    this.modalStateChange.emit(this.open);
  }

  closeModal() {
    console.log("fechando modal");

    this.open = false;
    this.modalStateChange.emit(this.open);
  }
}
