import { Component, Input, OnChanges, SimpleChanges, TemplateRef, booleanAttribute } from '@angular/core';

@Component({
  selector: 'sigecom-menu-bar',
  template: `
    <div class="flex items-center gap-4 pr-3" [ngClass]="{ 'relative': enableModal }">
      <ng-container *ngIf="enableModal; else notEnableModal">
        <button title="Opções" (click)="toggleModalActions();" class="p-2">
          <fa-icon icon="ellipsis-vertical" class="text-mms-text-primary" />
        </button>
      </ng-container>

      <ng-template #notEnableModal>
        <ng-content></ng-content>
      </ng-template>
    </div>

    <sigecom-modal clickOutside [open]="showModal" [showCloseButton]="true" (modalStateChange)="changeModalState($event)">
      <ng-container *ngTemplateOutlet="modalTemplate"></ng-container>
    </sigecom-modal>
  `,
  styles: [
  ]
})
export class MenuBarComponent {
  @Input({ transform: booleanAttribute }) enableModal: boolean = false;
  @Input() modalTemplate: TemplateRef<any> | null = null;

  showModal: boolean = false;

  toggleModalActions() {
    this.showModal = !this.showModal;
  }

  changeModalState(state: boolean) {
    this.showModal = state;
  }
}
