import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { faChevronLeft, faChevronRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sigecom-paged-dropdown',
  template: `
    <div class="relative inline-block border border-solid rounded-md p-2 bg-white w-full"
      [ngClass]="{
        'border-sigecom-danger': errorMessage,
        'border-sigecom-gray': !errorMessage && !isRequired,
        'border-sigecom-gray ring-0': !errorMessage && isRequired
      }"
    >
      <span class="font-bold text-sm py-0 px-2" [ngClass]="{ 'text-sigecom-danger': errorMessage, 'text-sigecom-gray': !errorMessage }">
        {{ label }}
        <span *ngIf="isRequired" class="text-sigecom-danger">*</span>
      </span>

      <div (click)="toggleDropdown()" class="relative cursor-pointer bg-white px-3 py-1 flex items-center w-full">
        <span class="text-black flex-grow truncate text-sm pl-1" title="{{ selectedOption ? getOptionLabel(selectedOption) : placeholder }}">
          {{ selectedOption ? getOptionLabel(selectedOption) : placeholder }}
        </span>
        <fa-icon [icon]="faAngleDown" class="text-gray-500"></fa-icon>
      </div>

      <div *ngIf="isOpen" class="absolute left-0 mt-1 w-full max-w-lg rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 overflow-hidden">
        <div class="py-2 max-h-60 overflow-auto">
          <div (click)="clearSelection()" class="cursor-pointer px-4 py-2 text-black text-sm bg-white hover:bg-gray-50 border-b" title="{{ placeholder }}">
            {{ placeholder }}
          </div>
          <div *ngFor="let option of options" (click)="selectOption(option)" class="cursor-pointer px-4 py-2 text-black text-sm bg-white hover:bg-gray-50 border-b whitespace-nowrap" [title]="getOptionLabel(option)">
            {{ getOptionLabel(option) }}
          </div>
        </div>

        <nav class="w-full px-4 py-2" role="navigation" aria-label="pagination">
          <ul class="flex justify-between items-center text-sm">
            <li>
              <button class="flex items-center justify-center w-8 h-8 text-gray-500 hover:text-gray-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none" (click)="prevPage($event)" [disabled]="currentPage === 0" aria-label="Página anterior">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
              </button>
            </li>
            <li class="flex-1 text-center">
              <span class="inline-block px-5 text-gray-700 border border-gray-300 rounded-lg bg-gray-100">
                {{ currentPage + 1 }} / {{ totalPages }}
              </span>
            </li>
            <li>
              <button class="flex items-center justify-center w-8 h-8 text-gray-500 hover:text-gray-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none" (click)="nextPage($event)" [disabled]="currentPage >= totalPages - 1" aria-label="Próxima página">
                <fa-icon [icon]="faChevronRight"></fa-icon>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  `,
})
export class PagedDropdownComponent<T extends Record<string, any>> implements ControlValueAccessor, OnInit, OnChanges {
  @Input() options: T[] = [];
  @Input() optionLabel!: keyof T;
  @Input() placeholder: string = 'Selecione...';
  @Input() label: string = '';
  @Input() isRequired: boolean = false;
  @Input() errorMessage?: string;
  
  @Input() currentPage: number = 0;
  @Input() totalPages: number = 1;

  @Output() selectedChange = new EventEmitter<T | null>();
  @Output() pageChange = new EventEmitter<number>();

  isOpen = false;
  selectedOption?: T;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faAngleDown = faAngleDown;

  private onTouchedCallback = () => {};
  private onChangeCallback = (value: any) => {};

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  writeValue(value: T | null): void {
    this.selectedOption = value || undefined;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: T) {
    this.selectedOption = option;
    this.selectedChange.emit(option);
    this.onChangeCallback(option);
    this.isOpen = false;
  }

  clearSelection() {
    this.selectedOption = undefined;
    this.selectedChange.emit(null);
    this.onChangeCallback(null);
    this.isOpen = false;
  }

  prevPage(event: Event) {
    event.stopPropagation();
    if (this.currentPage > 0) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  nextPage(event: Event) {
    event.stopPropagation();
    if (this.currentPage < this.totalPages - 1) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.onTouchedCallback();
    }
  }

  getOptionLabel(option: T): string {
    return option[this.optionLabel] as unknown as string;
  }
}
