import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-box-info',
  template: `
    <div class="grid grid-cols-1 gap-y-4">
      <div class="col-span-full rounded-xl border border-blue-700 p-4 mb-2">
        <div class="flex flex-row items-start">
          <img src="assets/images/woman-info.png" class="w-20 h-auto mr-4">
        <div>
        <h3 class="text-blue-800 font-bold">{{ attentionText }}</h3>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./box-info.component.scss']
})
export class BoxInfoComponent {
  @Input() attentionText: string = '';
  
  constructor() { }

}
