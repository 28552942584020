<header class="p-5 flex justify-between items-center bg-cbde-dark-blue">
  <div class="flex items-center gap-8" *ngIf="!environment.devBeta">
    <fa-icon icon="bars" class="text-white text-xl cursor-pointer" (click)="toggleSideNav()"></fa-icon>
    <p class="text-white">Olá, usuário de Federação — <strong>{{ getUserName() }}</strong></p>
  </div>

  <div class="flex items-center gap-2">
    <a class="bg-white cursor-pointer py-1 px-4 rounded flex items-center gap-2" (click)="profile()">
      <fa-icon icon="circle-user" class="text-sigecom-gray text-lg"></fa-icon>
      <span class="text-sigecom-gray">Perfil</span>
    </a>
    <a class="bg-white cursor-pointer py-1 px-4 rounded flex items-center gap-2" (click)="logout()">
      <fa-icon icon="right-from-bracket" class="text-sigecom-gray text-lg"></fa-icon>
      <span class="text-sigecom-gray">Sair</span>
    </a>
  </div>
</header>
