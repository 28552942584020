import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class GeneralGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  isAuthenticated(): boolean {
    const haveAccess = this.authService.getAccessTokenIfValidated() != null;
    if (!haveAccess) this.router.navigate(["/", "login"]).then();
    return haveAccess;
  }

  ifAuthenticatedBlock(): boolean {
    const isAuthenticated = !!this.authService.getAccessTokenIfValidated();
    if (isAuthenticated) this.router.navigate(["/"]).then();
    return !isAuthenticated;
  }

  denyAll(): boolean {
    this.router.navigate(["/", "atletas"]).then();
    return false;
  }

  permitAll(): boolean {
    return true;
  }

  isCbdeAdmin(): boolean {
    const isAuthenticated = this.isAuthenticated();
    if (isAuthenticated) {
      const isAdmin = this.authService.getCurrentValueOfTokenData()?.usuTxPerfis?.some(role => role === "ROLE_CBDE");
      if (isAdmin) return isAdmin;
    }
    this.router.navigate(["/"]).then();
    return false;
  }

  ifHaveProfile(...authorizedProfiles: string[]): boolean {
    const isAuthenticated = this.isAuthenticated();
    if (isAuthenticated) {
      const userProfiles: string[] | undefined = this.authService.getCurrentValueOfTokenData()?.usuTxPerfis;
      if (!userProfiles) return false;
      let isAuthorized: boolean = false;
      for (let authorizedProfile in authorizedProfiles) {
        isAuthorized = userProfiles.some(role => role === authorizedProfile);
        if (isAuthorized) break;
      }
      return isAuthorized;
    }
    this.router.navigate(["/"]).then();
    return false;
  }
}
