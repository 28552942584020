import {AfterContentInit, booleanAttribute, Component, ContentChild, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { AbstractControl, FormControlName, Validators } from '@angular/forms';
import { InputDirective } from '@shared/directives/input.directive';

@Component({
  selector: 'sigecom-input-container',
  template: `
    <div
      class="border border-solid rounded-md p-2 bg-white {{ class }}"
      [ngClass]="{
        'border-sigecom-danger': errorMessage,
        'border-sigecom-gray': !errorMessage && !((isRequired || inputRequired) && enableRequiredBorder),
        'opacity-40 pointer-events-none': (isDisabled || inputDisabled),
        'border-sigecom-info ring-1 ring-blue-500': !errorMessage && ((isRequired || inputRequired) && enableRequiredBorder),
      }"
    >
      <span class="font-bold text-sm py-0 px-2"
        [ngClass]="{
          'text-sigecom-danger': errorMessage,
          'text-sigecom-gray': !errorMessage ,
        }"
      >
        {{ label }}
        <span class="text-sigecom-danger">
          {{ (isRequired || inputRequired) ? '*' : '' }}
        </span>
      </span>

      <!-- <div #contentWrapper> -->
      <div>
        <ng-content></ng-content>
      </div>
    </div>
    <div *ngIf="errorMessage" class="text-sigecom-danger text-sm">
      <small>{{ errorMessage }}</small>
    </div>
  `,
  styleUrls: ['./input-container.component.scss']
})
// TODO: ADICIONAR VARIANTE COM CAIXA DE BUSCA E EMITIDOR DE EVENTOS.
export class InputContainerComponent {
  // @ViewChild('contentWrapper') content?: ElementRef;

  @ContentChild(InputDirective, {static: false, read: ElementRef}) inputElement?: ElementRef;
  @ContentChild(FormControlName) controlName?: FormControlName;

  @Input() label: string = "[ DEFAULT LABEL ]";
  @Input() type: InputTypeEnum = InputTypeEnum.INPUT;
  @Input({ transform: booleanAttribute }) enableRequiredBorder: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() errorMessage?: string;
  @Input() class?: string;

  protected readonly InputTypeEnum = InputTypeEnum;

  get inputDisabled(): boolean {
    return this.inputElement ? this.inputElement.nativeElement.disabled : false;
  }

  get inputRequired(): boolean {
    if (this.controlName?.control?.hasValidator(Validators.required)) {
      return true;
    }
    return this.inputElement ? this.inputElement.nativeElement.required : false;
  }
}

export enum InputTypeEnum {
  INPUT,
  SELECT,
}
