import {Component, OnDestroy, OnInit} from '@angular/core';
import {map, Subscription, tap, timer} from "rxjs";
import {SnackbarProps, SnackbarService} from "../../services/snackbar.service";
import { calculateDisplayTime } from 'src/app/core/utilities/utility-functions';

@Component({
  selector: 'sigecom-snackbar',
  template: `
    <div class="fixed z-[999] bg-transparent w-fit mx-auto max-w-[80%] bottom-4 left-0 right-0">
      <div *ngFor="let snackbar of snackbars; let i = index" class="py-2 px-3 mb-2 min-h-[3.5rem] backdrop-blur-md bg-gradient-to-r rounded-md flex gap-5 items-center shadow-md animate-bottom-expand-fast"
        [ngClass]="{
          'text-sigecom-danger gradient-error': snackbar.type === SnackbarType.danger,
          'text-sigecom-warn gradient-warn': snackbar.type === SnackbarType.warn,
          'text-sigecom-success gradient-success': snackbar.type === SnackbarType.success,
          'text-sigecom-info gradient-info': snackbar.type === SnackbarType.info,
        }">
        <div class="flex items-center gap-4 text-lg font-normal flex-1">
          <ng-container [ngSwitch]="snackbar.type">
            <fa-icon [icon]="'circle-info'" *ngSwitchCase="SnackbarType.info"></fa-icon>
            <fa-icon [icon]="'circle-check'" *ngSwitchCase="SnackbarType.success"></fa-icon>
            <fa-icon [icon]="'circle-exclamation'" *ngSwitchCase="SnackbarType.danger"></fa-icon>
            <fa-icon [icon]="'triangle-exclamation'" *ngSwitchCase="SnackbarType.warn"></fa-icon>
          </ng-container>
          <div *ngIf="!snackbar.isHtml">{{ snackbar.message }}</div>
          <div *ngIf="snackbar.isHtml" [innerHTML]="snackbar.message"></div>
        </div>
        <fa-icon [icon]="'xmark'" (click)="removeMessage(i)"></fa-icon>
      </div>
    </div>
  `,
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  public snackbars: SnackbarProps[] = [];
  private subscription?: Subscription;

  SnackbarType = SnackbarType;

  constructor(private snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.subscription = this.snackbarService.snackBarProps$
      .subscribe(props => {
        this.snackbars = props;
        props.forEach((prop, index) => this.setupAutoHide(index, prop?.message!));
      });
  }

  private setupAutoHide(index: number, message: string) {
    timer(calculateDisplayTime(message)).subscribe(() => this.removeMessage(index));
  }

  public removeMessage(index: number) {
    this.snackbarService.removeMessage(index);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

export enum SnackbarType {
  danger = 'danger',
  success = 'success',
  info = 'info',
  warn = 'warn',
};
