import { Component } from '@angular/core';

@Component({
  selector: 'sigecom-list-item',
  template: `
    <div class="w-full p-4 bg-white rounded-md shadow-flat-xl relative">
      <ng-content></ng-content>
    </div>
  `,
})
export class ListItemComponent {

}
