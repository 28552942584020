import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import { AttachmentService } from './attachment.service';
import { AttachmentUtils } from '../../utilities/attachment-utils';

@Injectable({
  providedIn: 'root'
})
export class MediaDisplayerService {
  private active: Subject<boolean> = new Subject<boolean>();
  isActive: Observable<boolean> = this.active.asObservable();

  private base64: Subject<string> = new Subject<string>();
  getBase64: Observable<string> = this.base64.asObservable();

  constructor(private attachmentService: AttachmentService) {
    this.active.next(false);
  }

  close(): void {
    this.active.next(false);
  }

  sendContent(content: string): void {    
    AttachmentUtils.isBase64(content) ? this.base64.next(content) : this.getBase64FromUrl(content); 
    this.active.next(true);
  }

  private getBase64FromUrl(url: string): void {    
    this.attachmentService.getAttachmentByUrl(url).subscribe({
      next: (result) => this.base64.next(result),
      error: () => {
        this.close();
      }
    });
  }
  
  // TODO:
  // montar esquema para desativação em casos de erros interceptados
  // estudar esquema de cache no local storage para que uma vez buscado o base64 isente a necessidade de fazer uma nova requisição
}