import {state, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {flyInEnter, flyInLeave} from "../../animations/fly";
import {IMenuItem, MenuItem} from "../../../models/menu-item";
import {SideNavService} from '../../services/side-nav.service';
import {Subscription} from 'rxjs';
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'sigecom-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('OpenMenu', [
      state('flyIn', style({transform: 'translateY(0)'})),
      transition(':enter', [useAnimation(flyInEnter)]),
      transition(':leave', [useAnimation(flyInLeave)]),
    ]),
  ],
})
export class SideNavComponent implements OnInit, OnDestroy {
  public hideSideNav: boolean = false;
  public menuItens: MenuItem[] = [];

  public enable: boolean = environment.enableMarquee;

  private subscription?: Subscription;

  constructor(
    public router: Router,
    private sideNavService: SideNavService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.subscription = this.sideNavService.isHide.subscribe(value => this.hideSideNav = value);
    this.hideSideNav = window.innerWidth <= 1100;
    this.createMenu();
  }

  createMenu() {
    const perfis = this.authService.getPerfisUsuario();

    const isAdmin = perfis.includes('ROLE_CBDE');
    const isFederacao = perfis.includes('ROLE_DIRIGENTE') || perfis.includes('ROLE_DIRETOR') || perfis.includes('ROLE_PRESIDENTE_DE_FEDERACAO');
    const isVoluntario = perfis.includes('ROLE_VOLUNTARIO');
    const isSecretaria = perfis.includes('ROLE_SECRETARIA');
    const isOperacoes = perfis.includes('ROLE_OPERACOES');
    const isInstitution = perfis.includes('ROLE_REPRESENTANTE_DE_INSTITUICAO_DE_ENSINO');

    // Dashboard
    const dashboard = new MenuItem('Dashboard', ['dashboard'], 'square-poll-vertical', []);
    if (isAdmin) {
      this.addMenuItem(dashboard, []);
    }

    // Módulo de cadastro
    const cadastro = new MenuItem('Cadastro', ['cadastro'], 'users', []);
    const usuarios = new MenuItem('Usuários', ['usuarios'], 'person', []);
    const validadores = new MenuItem('Validadores', ['usuarios', 'validadores'], 'gear', []);
    const federacoes = new MenuItem('Federações', ['federacoes'], 'sign-hanging', []);
    const instituicoes = new MenuItem('Instituições de Ensino', ['instituicoes'], 'book', []);
    // const atletas = new MenuItem('Atletas', ['atletas'], 'person-running', []);
    //const funcoes = new MenuItem('Função', ['funcoes'], 'engineering', []);

    const subItems = [];
    if (isAdmin || isOperacoes) {
      subItems.push(usuarios, validadores, federacoes, instituicoes);
    } else if (isFederacao) {
      subItems.push(usuarios, instituicoes);
    } else if (isInstitution) {
      subItems.push(usuarios);
    } else if (isVoluntario || isSecretaria) {
      subItems.push(usuarios);
    }

    this.addMenuItem(cadastro, subItems);

    // Módulo de Eventos
    const eventsSubItens: MenuItem[] = [];
    const moduloEventos = new MenuItem('Eventos', ['modulo-eventos'], 'trophy', eventsSubItens);
    const cadastroEventos = new MenuItem('Cadastro de eventos', ['eventos'], 'ranking-star', []);
    const cadastroCampeonatos = new MenuItem('Cadastro de competições das modalidades', ['competicoes'], 'dumbbell', []);
    // const validacoesCategorias = new MenuItem('Validações de categorias', ['eventos', 'validacoes-categoria'], 'scale-balanced', []);
    const validacoesCategorias = new MenuItem('Gerenciamento de validações', ['eventos', 'gerenciamento-validacoes'], 'scale-balanced', []);
    const funcoes = new MenuItem('Funções', ['eventos', 'listar-funcoes'], 'briefcase', []);

    if (isAdmin || isFederacao) {
      eventsSubItens.push(cadastroEventos, cadastroCampeonatos, validacoesCategorias, funcoes);
      this.menuItens.push(moduloEventos);
    }

    // Módulo de Inscrições
    const inscriptionSubItems: MenuItem[] = [];
    const moduloInscricoes = new MenuItem('Inscrições', ['modulo-inscricoes'], 'ticket', inscriptionSubItems);
    const inscricaoAtleta = new MenuItem('Inscrição de atletas', ['inscricoes/atletas'], 'id-card', []);
    const inscricaoPrestador = new MenuItem('Inscrição de dirigentes', ['inscricoes/dirigentes'], 'id-card', []);
    const inscricaoComissaoTecnica = new MenuItem('Inscrição de comissão técnica', ['inscricoes/comissao-tecnica'], 'id-card', []);
    const inscricaoColaborador = new MenuItem('Inscrição de colaboradores', ['inscricoes/colaboradores'], 'id-card', []);

    if (isAdmin || isOperacoes) {
      inscriptionSubItems.push(inscricaoAtleta, inscricaoPrestador, inscricaoComissaoTecnica, inscricaoColaborador)
    } else if (isFederacao || isInstitution) {
      inscriptionSubItems.push(inscricaoAtleta, inscricaoPrestador, inscricaoComissaoTecnica);
    } else if (isVoluntario) {
      inscriptionSubItems.push(inscricaoPrestador);
    }

    this.menuItens.push(moduloInscricoes);

    // Módulo Serviços
    const servicesSubItens: MenuItem[] = [];
    const moduloServicos = new MenuItem('Serviços', ['modulo-servicos'], 'plate-wheat', servicesSubItens);
    const cadatroBeneficios = new MenuItem('Cadastro de Benefícios', ['beneficios'], 'utensils', []);
    const cadatroServicos = new MenuItem('Cadastro de Serviços', ['servicos'], 'receipt', []);
    const vincularServicosUsuario = new MenuItem('Serviços dos usuários', ['servicos', 'vinculo-usuario'],'cookie', [] );
    const resgistrosServicos = new MenuItem('Registros', ['registros'], 'cookie-bite', []);
    const resgistrosServicosResumo = new MenuItem('Registros Resumo', ['registros', 'resumo'], 'cookie-bite', []);
    servicesSubItens.push(cadatroBeneficios, cadatroServicos, vincularServicosUsuario, resgistrosServicos, resgistrosServicosResumo);
    if (isAdmin || isFederacao) {
      this.menuItens.push(moduloServicos);
    }

    // Módulo de relatórios
    if (!isVoluntario && !isSecretaria && !isInstitution) {
      const exportacoesSubsItens: MenuItem[] = [];
      const moduloExportacoes = new MenuItem('Exportações', ['menu-exportacoes'], 'box-archive', exportacoesSubsItens);

      const moduloRelatorios = new MenuItem('Relatórios', ['exportacoes', 'relatorios'], 'chart-pie', []);

      const aplicacoes = new MenuItem('Aplicações', ['downloads'], 'table-cells', []);

      const relatoriosAgendados = new MenuItem('Exportações agendadas', ['exportacoes', 'relatorios-agendados'], 'chart-line', []);

      exportacoesSubsItens.push(relatoriosAgendados);
      exportacoesSubsItens.push(moduloRelatorios);

      if (isAdmin) {
        exportacoesSubsItens.push(aplicacoes);
      }

      // const moduloCertificados = new MenuItem('Certificados', ['exportacoes', 'certificados'], 'library_books', []);
      // exportacoesSubsItens.push(moduloCertificados);

      this.menuItens.push(moduloExportacoes);
      // this.addMenuItem(moduloExportacoes, [moduloRelatorios, moduloCertificados]);
    }

  }

  addMenuItem(item: MenuItem, subItems: MenuItem[]) {
    item.addSubMenuItems(subItems)
    this.menuItens.push(item);
  }

  isActiveRoute(route: string[]) {
    const routeString = route.join("/");
    return this.router.isActive(routeString, {
      paths: 'subset',
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored'
    });
  }

  activeMenu(menuItem: IMenuItem) {
    if (menuItem.subitens.length) {
      menuItem.switch!();
    } else {
      this.router.navigate(menuItem.route).then();
    }
  }

  redirectTo() {
    this.router.navigateByUrl('').then();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected readonly environment = environment;
}

