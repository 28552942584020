import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MessageErrorResolver} from "../../utilities/message-error-resolver";
import {LoadingService} from "./loading.service";
import {catchError, EMPTY, map, Observable, switchMap, tap} from "rxjs";
import {ResponseDto} from "../../models/exceptions/response-dto";
import {AttachmentPostForm} from "../../models/form/attachment-post-form";
import {IAttachmentDto} from "../../models/portal/users/dtos/attachment-dto";
import { AttachmentUtils } from '../../utilities/attachment-utils';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  private url: string = environment.apiUrl + "/v1/anexo";

  constructor(
    private httpClient: HttpClient,
    private messageErrorResolver: MessageErrorResolver,
    private loadingService: LoadingService
  ) { }

  public getAttachmentByUrl(url: string): Observable<string> {
    const params = new HttpParams().set('url', url);
    return this.httpClient.get(`${this.url}`, { params , responseType: 'blob' })
    .pipe(
      switchMap((res: Blob) => AttachmentUtils.convertBlobToBase64(res)),
    );
  }

  public postEventAttachment(form: AttachmentPostForm, eveNrId: number): Observable<void> {
    return this.httpClient.post<void>(this.url.concat(`/evento/${eveNrId}`), form)
      .pipe(
        catchError(err => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(err);
          return EMPTY;
        }),
        tap(() => this.loadingService.deactivate())
      );
  }

  public getEventAttachment(eveNrId: number, attachmentType: "CREDENCIAL" | "CERTIFICADO"): Observable<IAttachmentDto> {
    const params: HttpParams = new HttpParams().set("tipoAnexo", attachmentType);
    return this.httpClient.get<ResponseDto<IAttachmentDto>>(this.url.concat(`/evento/${eveNrId}`), { params: params })
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          return EMPTY;
        }),
      );
  }
}
