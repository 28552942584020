import { Component } from '@angular/core';

@Component({
  selector: 'app-events',
  template: `
    <router-outlet></router-outlet>
    <sigecom-loading/>
  `,
})
export class EventsComponent {

}
