import { HttpResponse } from "@angular/common/http";
import { Observable, catchError, filter, map, of, pipe, tap } from "rxjs";
import { AttachmentUtils } from "../../utilities/attachment-utils";
import { IResponseDto } from "@models/exceptions/response-dto";

export function downloadFile<T>() {
  return pipe(
    tap((res: HttpResponse<T>) => {
      if (res.body instanceof ArrayBuffer) {
        AttachmentUtils.downloadFile(res as HttpResponse<ArrayBuffer>);
      }
    }),
  )
}

export function catchDownloadFileError(errorHandler: Function) {
  return pipe(
    catchError(async (err: any, caught: Observable<any>) => {
      const responseError = await AttachmentUtils.handleArrayBufferError(err);
      errorHandler(responseError!);
      return caught;
    }),
  )
}

export function extractResponse<T>(): (source: Observable<IResponseDto<T>>) => Observable<T> {
  return (source: Observable<IResponseDto<T>>) => source.pipe(
    map(response => response.response)
  );
}

export function notNull<T>(action?: () => void): (source: Observable<T>) => Observable<NonNullable<T>> {
  return (source: Observable<T>) => source.pipe(
    tap(value => {
      if ((value === null || value === undefined) && action) {
        action();
      }
    }),
    filter(value => value !== null && value !== undefined),
    map(value => value as NonNullable<T>)
  );
}
