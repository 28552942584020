import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ResponseDto, ResponseWithErrorDto} from "../../models/exceptions/response-dto";
import {catchError, EMPTY, map, Observable, tap} from "rxjs";
import {IFederationDto} from "../../models/federation-dto";
import {IPage} from "../../models/pagination/page";
import {MessageErrorResolver} from "../../utilities/message-error-resolver";
import {LoadingService} from "./loading.service";
import { IListFederationDto } from '../../models/list-federation-dto';

@Injectable({
  providedIn: 'root'
})
export class FederationService {
  private url: string = environment.apiUrl + "/v1/federacoes";

  constructor(private httpClient: HttpClient,
              private messageErrorResolver: MessageErrorResolver,
              private loadingService: LoadingService) {
  }

  public getAllFederations(searchText?: string, page?: number, size?: number): Observable<IPage<IListFederationDto>> {
    let params = new HttpParams();
    if (size) params = params.set("size", size);
    if (page) params = params.set("page", page);
    if (searchText) params = params.set("fedTxNome", searchText);

    return this.httpClient.get<ResponseDto<IPage<IListFederationDto>>>(this.url, {params})
      .pipe(
        map(response => response.response),
        map((page) => {
          return {
            ...page,
            content: page.content?.map(federation => {
              const sigla = federation.fedTxSigla;
              const nome = federation.fedTxNome;
              const fedTxNome = `${sigla} - ${nome}`;
              return {
                ...federation,
                fedTxNome
              } as IListFederationDto;
            })
          } as IPage<IListFederationDto>;
        }),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        }),
      );
  }

  getDirectorUserFederation() {
    return this.httpClient.get<ResponseDto<IPage<IFederationDto>>>(this.url + "/diretor")
      .pipe(
        map(response => response.response),
        map((page) => {
          return {
            ...page,
            content: page.content?.map(federation => {
              const sigla = federation.fedTxSigla;
              const nome = federation.fedTxNome;
              const fedTxNome = `${sigla} - ${nome}`;
              return {
                ...federation,
                fedTxNome
              } as IFederationDto;
            })
          } as IPage<IFederationDto>;
        }),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        }),
      );
  }

  getFederationById(id: number): Observable<IFederationDto> {
    this.loadingService.activate();
    let params = new HttpParams();
    params.set("idFederacao", id);
    return this.httpClient.get<ResponseDto<IFederationDto>>(this.url + `/${id}`, {params})
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          throw EMPTY;
        }),
        tap(() => this.loadingService.deactivate())
      );
  }

  getCbdeFederation() {
    return this.httpClient.get<ResponseDto<IFederationDto>>(this.url.concat("/cbde"))
      .pipe(
        map(response => response.response),
        catchError(err => {
          throw EMPTY;
        })
      );
  }
}
