import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  private hideSideNav: Subject<boolean> = new Subject<boolean>();
  isHide: Observable<boolean> = this.hideSideNav.asObservable();

  constructor() {
    this.hideSideNav.next(false);
  }

  show(): void {
    this.hideSideNav.next(false);
  }

  hide(): void {
    this.hideSideNav.next(true);
  }
}
