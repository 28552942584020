import { IPage } from './../../../models/pagination/page';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IFederationDto } from 'src/app/core/models/federation-dto';
import { FederationService } from '../../services/federation.service';
import { Subject, Subscription } from 'rxjs';
import { IEventDto } from 'src/app/core/models/event-dto';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'sigecom-top-list',
  templateUrl: './top-list.component.html',
  styleUrls: ['./top-list.component.scss']
})
export class TopListComponent implements OnInit, OnDestroy{
  private subscriptions: Subscription[] = [];

  @Input() listName: string = 'list';
  @Input() placeholder: string = 'Pesquisar...';

  @Input() buttonNavigateLabel?: string = 'default';
  @Output() buttonNavigateAction: EventEmitter<any> = new EventEmitter<any>();

  @Output() searchText: EventEmitter<string> = new EventEmitter<string>();

  @Input() isCbdeAdmin?: boolean = false;
  @Input() hasEventFilter: boolean = false;

  public federations?: IPage<IFederationDto>;
  public events?: IPage<IEventDto>;

  public selectedFederationId?: number;

  @Output() selectedFederationIdEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedEventIdEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(private federationService: FederationService, private eventService: EventService) {}

  ngOnInit(): void {
    if (this.isCbdeAdmin) {
      this.federationService.getAllFederations().pipe().subscribe({
        next: (federations) => this.federations = federations
      });
    }
    if (this.hasEventFilter) {
      
    }
  }

  public create() {
    this.buttonNavigateAction.emit();
  }

  public search(searchValue: string) {
    this.searchText.emit(searchValue);
  }

  public onChangeFederation(event: Event) {
    if (this.isCbdeAdmin) {
      const fedNrId = (event.target as HTMLSelectElement).value;
      this.selectedFederationIdEvent.emit(Number(fedNrId));
    }
  }

  public onChangeEvent(event: Event) {
    if (this.hasEventFilter) {
      const eveNrId = (event.target as HTMLSelectElement).value;
      this.selectedEventIdEvent.emit(Number(eveNrId));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
