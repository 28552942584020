<div class="fixed top-0 z-90 w-full h-full pt-[4.7rem] pointer-events-auto cursor-not-allowed bg-black/40 pr-[2.6rem]" [@EnterLeave]="'flyIn'" *ngIf="active">
  <div class="w-[80%] h-2 overflow-hidden bg-white ">
    <div class="h-full w-[50%] rounded-full animate-progress bg-cbde-green"></div>
  </div>
</div>



<!-- <div class="fixed z-[100] inset-0 flex items-center bg-sigecom-modal-dark" [@EnterLeave]="'flyIn'" *ngIf="active">
  <div class="bg-cbde-dark-blue w-full">
    <div class="h-48 flex flex-col gap-4 items-center justify-center">
      <div class="w-[80%]">
        <p class="text-white text-3xl font-bold">Carregando</p>
        <p class="text-white">Aguarde um momento, por favor.</p>
      </div>
      <div class="w-[80%] h-4 rounded-full overflow-hidden bg-white">
        <div class="h-full w-[50%] animate-progress bg-cbde-dark-blue"></div>
      </div>
    </div>
  </div>
</div> -->
