import {AfterViewInit, booleanAttribute, Component, ElementRef, EventEmitter, Input, numberAttribute, Output, ViewChild} from '@angular/core';
import { PageRequest } from '@models/pagination/page-request';

@Component({
  selector: 'sigecom-select-search-container',
  template: `
      <div
        class="rounded-md border border-sigecom-{{ errorMessage ? 'danger' : 'outlined-gray' }} min-h-[3.75rem] transition-colors duration-300 ease-in
        text-sigecom-outlined-gray p-2"
        [ngClass]="{
          'pointer-events-none opacity-30': isDisabled
        }"
      >
        <div  class="flex items-center justify-between">
          <label class="font-bold text-sm p-2 mb-1 block w-full text-sigecom-{{ errorMessage ? 'danger' : 'gray' }}">
            {{ label }} {{ isRequired ? '*' : '' }}
          </label>

          <div class="space-x-2 flex items-center" *ngIf="enablePagination">
            <p class="font-normal text-sm">Página: </p>
            <strong class="flex items-center gap-2.5 text-pec-tx-primary">
              <button type="button" (click)="prevPage()" class="disabled:text-sigecom-gray" [disabled]="currentPage.page === 0">
                <fa-icon icon="angle-left" class="text-lg"/>
              </button>
              <span class="text-sm">{{ currentPage.page! + 1  }}</span>
              <button type="button" (click)="nextPage()" class="disabled:text-sigecom-gray" [disabled]="currentPage.page! >= totalPages! - 1">
                <fa-icon icon="angle-right" class="text-lg"/>
              </button>
            </strong>
            <p class="font-normal text-sm">Itens: </p>
            <strong class="flex items-center gap-2.5 text-pec-tx-primary">
              <button type="button" (click)="subSize()" class="disabled:text-sigecom-gray" [disabled]="currentPage.size! <= minSize">
                <fa-icon icon="angle-left" class="text-lg"/>
              </button>
              <span class="text-sm">{{ currentPage.size }}</span>
              <button type="button" (click)="addSize()" class="disabled:text-sigecom-gray" [disabled]="currentPage.size! >= maxSize">
                <fa-icon icon="angle-right" class="text-lg"/>
              </button>
            </strong>
          </div>
        </div>

        <input
          class="w-full outline-none border border-gray-300 rounded mb-2 focus:ring-1 focus:ring-cbde-primary"
          #searchInput type="text" placeholder="Pesquise..."
          (keyup)="searchEmmit.emit(searchInput.value)"
        />

        <div class="border border-gray-300 rounded" #contentWrapper>
          <ng-content></ng-content>
        </div>
      </div>
      <div *ngIf="errorMessage" class="text-xs mt-1 text-sigecom-danger">
        <div>
            {{ errorMessage }}
        </div>
      </div>
  `,
  styleUrls: ['./select-search-container.component.scss']
})
export class SelectSearchContainerComponent implements AfterViewInit {
  @ViewChild('contentWrapper') content?: ElementRef;
  @Input() label: string = "[ DEFAULT LABEL ]";

  @Input({ transform: booleanAttribute }) enablePagination: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() errorMessage?: string;
  @Output() searchEmmit: EventEmitter<string> = new EventEmitter<string>();

  currentPage: PageRequest = { page: 0, size: 10 };

  @Input() totalPages: number = 10;
  @Output() pageChange = new EventEmitter<PageRequest>();

  protected maxSize = 100;
  protected minSize = 10;

  ngAfterViewInit(): void {
    const selectElement: HTMLSelectElement = this.content?.nativeElement.getElementsByTagName("select")[0];
    selectElement.size = 5;
    selectElement.classList.add("select-search");
  }

  nextPage() {
    if (this.currentPage.page! < this.totalPages! - 1) {
      this.currentPage.page!++;
    }
    this.pageChange.emit(this.currentPage);
  }

  prevPage() {
    if (this.currentPage.page! > 0) {
      this.currentPage.page! -= 1;
    }
    this.pageChange.emit(this.currentPage);
  }

  addSize() {
    if (this.currentPage.size! < this.maxSize) {
      this.currentPage.size! += 10;
    }
    this.pageChange.emit(this.currentPage);
  }

  subSize() {
    if (this.currentPage.size! > this.minSize) {
      this.currentPage.size! -= 10;
    }
    this.pageChange.emit(this.currentPage);
  }
}
