import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sigecom-switch',
  template: `
    <label class="w-11 h-6 relative inline-block">
      <input class="opacity-0 w-0 h-0 peer checked:bg-cbde-dark-blue checked:translate-x-[26px]"
        type="checkbox" #switch [checked]="switchState" (change)="switchStateChange.emit(switch.checked)"
      >
      <span class="absolute cursor-pointer top-0 right-0 left-0 bottom-0 bg-[#ccc] rounded-full transition-all duration-300
        before:absolute before:content-[''] before:h-4 before:w-4 before:left-1 before:bottom-1 before:bg-white
        before:rounded-full before:transition-all before:duration-300 checked:bg-cbde-dark-blue peer-checked:bg-cbde-dark-blue
        peer-checked:before:translate-x-5">
      </span>
    </label>
  `,
})
export class SwitchComponent {
  @Input() switchState: boolean = false;
  @Output() switchStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
