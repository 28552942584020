import {Injectable} from '@angular/core';
import jwtDecode from 'jwt-decode';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, catchError, EMPTY, map, Observable, Subject, tap} from "rxjs";
import {IResponseDto} from "../../models/exceptions/response-dto";
import {IAuthenticationDto} from "../../models/authentication-dto";
import {AuthenticationPostForm} from "../../models/form/authentication-post-form";
import {MessageErrorResolver} from "../../utilities/message-error-resolver";
import {LoadingService} from "./loading.service";
import {IDecodedToken} from "../../models/decoded-token";
import { ResetPasswordForm } from '../../models/form/reset-password-form';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenData: BehaviorSubject<IDecodedToken | null> = new BehaviorSubject<IDecodedToken | null>(null);
  public getTokenData: Observable<IDecodedToken | null> = this.tokenData.asObservable();

  private credentialsKey: string = "access_token";

  constructor(private httpClient: HttpClient, private messageErrorResolver: MessageErrorResolver, private loadingService: LoadingService) {
  }

  getCurrentValueOfTokenData() {
    return this.tokenData.value;
  }

  isAdminUserAuthenticated() {
    const perfis = this.getPerfisUsuario();
    const isAdmin = perfis.includes('ROLE_CBDE');
    return isAdmin;
  }

  isFederationAuthenticated() {
    const perfis = this.getPerfisUsuario();
    const isFederation = perfis.includes('ROLE_DIRIGENTE') || perfis.includes('ROLE_DIRETOR') || perfis.includes('ROLE_PRESIDENTE_DE_FEDERACAO');
    return isFederation;
  }

  isInstitutionAuthenticated() {
    const perfis = this.getPerfisUsuario();
    const isIstitution = perfis.includes('ROLE_REPRESENTANTE_DE_INSTITUICAO_DE_ENSINO');
    return isIstitution;
  }

  getPerfisUsuario(): String[] {
    const token = this.getCurrentValueOfTokenData();
    if (token) {
      if (token.usuTxPerfis) {
        return token.usuTxPerfis;
      }
    }
    return [];
  }

  setAccessToken(token: string) {
    localStorage.setItem(this.credentialsKey, token);
    this.tokenData.next(this.#getDataFromToken(token));
  }

  verifyTokenAndSetupData() {
    const token = this.getAccessTokenIfValidated();
    if (token) {
      const dataFromToken = this.#getDataFromToken(token);
      this.tokenData.next(dataFromToken);
    }
  }

  #getDataFromToken(token: string): IDecodedToken | null {
    try {
      const data = jwtDecode<IDecodedToken>(token);
      return data;
    } catch (error: any) {
      return null;
    }
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.credentialsKey);
  }

  #checkExpiration(data: IDecodedToken): boolean {
    const expirationDate = new Date(data.exp! * 1000);
    const currentDate = new Date();
    return currentDate <= expirationDate;
  }

  getAccessTokenIfValidated(): string | null {
    const accessToken = this.getAccessToken();
    if (!accessToken) return null;
    const dataFromToken = this.#getDataFromToken(accessToken);
    if (!dataFromToken) return null;
    const isValid = this.#checkExpiration(dataFromToken);
    return isValid ? accessToken : null;
  }

  clearCredentials() {
    localStorage.removeItem(this.credentialsKey);
    this.tokenData.next(null);
  }

  validateRecaptcha(recaptcha: string): Observable<IResponseDto<any>> {
    return this.httpClient
      .post<IResponseDto<any>>(
        `${environment.apiUrl}/recaptcha/validate`,
        {recaptcha},
        {responseType: 'json'}
      );
  }

  signIn(form: AuthenticationPostForm) {
    this.loadingService.activate();
    return this.httpClient.post<IResponseDto<IAuthenticationDto>>(environment.apiUrl + "/v1/usuarios/autenticar", form)
      .pipe(
        tap(() => this.loadingService.deactivate()),
        map(response => response.response),
        catchError(err => {
          this.messageErrorResolver.resolveMessage(err);
          this.loadingService.deactivate();
          return EMPTY;
        }),
      );
  }


  resetPassword(form: ResetPasswordForm) {
    return this.httpClient.post<IResponseDto<void>>(environment.apiUrl + "/v1/usuarios/resetar-senha/sigecom", form)
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.messageErrorResolver.resolveMessage(err);
          this.loadingService.deactivate();
          throw err;
        }),
      );
  }
}
