export const messages: any = {
  validation: {
    required: 'Este campo é obrigatório.',
    minlength: 'O campo não atingiu o mínimo de caracteres.',
    maxlength: 'O campo excedeu o máximo de caracteres.',
    apiError: 'Erro ao processar a solicitação. Por favor, tente novamente mais tarde.',
    valorNaLista: 'Valor inválido. Selecione uma opção da lista disponível.',
    mask: 'O valor inserido não corresponde ao formato esperado.',
    min: 'O valor excedeu o mínimo permitido.',
    max: 'O valor excedeu o máximo permitido.',
    email: 'Digite um endereço de e-mail válido.',
    pattern: 'O formato do campo é inválido. Por favor, siga o padrão especificado.',
    birthdate: 'A data de nascimento não pode ser posterior a data atual e anterior a 100 anos atrás.'
  }
}
