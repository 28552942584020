<div class="bg-sigecom-modal-dark/40 fixed inset-0 w-full h-full z-[130] flex justify-center items-center" *ngIf="isOpen">
  <div class="w-auto min-w-[20%] p-2 overflow-y-auto max-h-[80%] max-w-5xl absolute flex flex-col bg-white rounded-md animate-open-modal">

    <header class="w-full h-10 flex justify-between items-center text-lg font-bold pl-2 pr-12 relative text-sigecom-gray" *ngIf="header">
      {{ header | titlecase }}
      <fa-icon>close</fa-icon>
    </header>

    <div class="w-full flex items-center gap-2 p-2 pr-5 text-sigecom-dark-gray">
      <ng-container [ngSwitch]="args?.modalType">
        <fa-icon [icon]="'info'" *ngSwitchCase="modalType.info"></fa-icon>
        <fa-icon [icon]="'check'" *ngSwitchCase="modalType.success"></fa-icon>
        <fa-icon [icon]="'triangle-exclamation'"i *ngSwitchCase="modalType.danger"></fa-icon>
        <fa-icon [icon]="'triangle-exclamation'" *ngSwitchCase="modalType.warn"></fa-icon>
      </ng-container>

      <p class="text-lg font-semibold">{{ args?.message }} <span>{{ args?.resourceName || 'este registro' }}</span>?</p>
    </div>

    <footer class="w-full mt-3">
      <div class="w-full flex justify-end gap-2">
          <sigecom-button
            icon="xmark" title="{{ args?.cancelText || 'Cancelar' }}"
            (buttonClicked)="cancel()"
          />

          <sigecom-button
            [type]="getButtonType()"
            icon="check" title="{{ args?.confirmText || 'Confirmar' }}"
            (buttonClicked)="confirm()"
          />
      </div>
    </footer>
  </div>
</div>
