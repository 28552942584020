import { booleanAttribute, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'sigecom-multiselect-search',
  template: `
    <sigecom-input-container
      [label]="label"
      [isRequired]="isRequired"
      [isDisabled]="isDisabled"
      [errorMessage]="dropdownClicked ? errorMessage : ''"
    >
      <div class="relative bg-white" #multiselectField>
        <div class="w-full top-12 rounded-md bg-white max-h-60 overflow-auto px-2 z-40 cursor-pointer">
          <div class="w-full sticky top-0 bg-white pt-2 px-2 flex items-center gap-2 mb-4">
              <input
                *ngIf="!useList"
                type="checkbox"
                (change)="selectAllOptions($event)"
                [checked]="areAllOptionsSelected()"
              >
              <div class="w-full relative" *ngIf="!disableSearch">
                  <input
                    type="text" placeholder="Buscar..." [(ngModel)]="searchText"
                    class="w-full h-9 border border-[#dadada] rounded-md relative pl-2 outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <fa-icon [icon]="'magnifying-glass'" class="absolute right-2 text-sigecom-dark-gray top-2"></fa-icon>
              </div>
          </div>

          <label
            class="flex leading-none items-center gap-2 mb-2 mr-2 ml-0.5 text-base"
            [ngClass]="{
              'cursor-pointer text-sigecom-dark-gray': !enableTemplate,
              'bg-white shadow-flat-xl px-2 py-3 rounded-md': enableTemplate,
            }"
            *ngFor="let op of listOptions(); let i = index"
          >
            <input
              *ngIf="!useList"
              name="op-check-list-{{i}}"
              type="checkbox"
              [value]="op[identifyProperty]"
              [checked]="isSelected(op)"
              (change)="onCheckboxChange($event)"
            >
            <span *ngIf="!enableTemplate" class="flex-1 max-w-[20rem] break-words">{{ op[displayProperty] }} </span>

            <div *ngIf="enableTemplate" class="flex-1">
              <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: op }"></ng-container>
            </div>
          </label>
        </div>
      </div>

    </sigecom-input-container>
  `,
})
export class MultiselectSearchComponent<T> {
  @ContentChild(TemplateRef) optionTemplate: TemplateRef<any> | null = null;

  @ViewChild('multiselectField') multiselectField?: ElementRef;

  @Input({ transform: booleanAttribute }) useList: boolean = false;
  @Input({ transform: booleanAttribute }) disableSearch: boolean = false;
  @Input() label: string = "Multiselect";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input({ transform: booleanAttribute }) enableTemplate: boolean = false;
  @Input() errorMessage?: string;

  _options: T[] = [];
  @Input() set options(options: T[]) {
    if (options === null || options === undefined) {
      this._options = [];
      return;
    }
    this._options = options;
  };

  @Input() identifyProperty!: keyof T;
  @Input() displayProperty!: keyof T;
  @Input() propertyDisplayedInInput?: keyof T;

  _selectedOptionsIds: number[] = [];
  @Input() set selectedOptionsIds(ops: number[]) {
    if (ops === null || ops === undefined) {
      this._selectedOptionsIds = [];
      return;
    }
    this._selectedOptionsIds = ops;
  };

  @Output() public selectedOptionsIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() public selectedOptionChange: EventEmitter<number> = new EventEmitter<number>();


  public dropdownStates: boolean = false;
  public dropdownClicked: boolean = false;

  public filteredOptionsArray: T[] = [];
  public searchText: string = '';

  constructor() {}

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(target: any) {
    if (this.multiselectField && !this.multiselectField.nativeElement.contains(target)) {
      this.dropdownStates = false;
    }
  }

  public displaySelectedValues(): string {
    const maxToShow = 5;
    const selectedCount = this._selectedOptionsIds.length;

    const selectedOptions = this._options?.filter(op => this._selectedOptionsIds?.includes(op[this.identifyProperty] as any));

    const displayProp = this.propertyDisplayedInInput || this.displayProperty;

    const selectedDisplayValues = selectedOptions?.map(op => op[displayProp]);

    if (selectedCount <= maxToShow) {
      return selectedDisplayValues?.join(', ');
    } else {
      const firstFiveSelected = selectedDisplayValues?.slice(0, maxToShow).join(', ');
      const remainingCount = selectedCount - maxToShow;
      return `${firstFiveSelected} +${remainingCount}`;
    }
  }

  public onCheckboxChange(event: any): void {
    if (this._selectedOptionsIds === null || this._selectedOptionsIds === undefined) {
      this._selectedOptionsIds = [];
    }

    const selectedOptionId: number = Number(event.target.value);

    const index = this._selectedOptionsIds?.indexOf(selectedOptionId);

    if (index === -1) {
      this.dropdownClicked = true;
      this._selectedOptionsIds?.push(selectedOptionId);
    } else {
      this._selectedOptionsIds?.splice(index!, 1);
    }

    this.selectedOptionsIdsChange.emit(this._selectedOptionsIds);
    this.selectedOptionChange.emit(selectedOptionId);
    this.validateMultselectChange();
  }

  public toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.dropdownStates = !this.dropdownStates;
  }

  public isSelected(op: T): boolean {
    if (!op || !this._selectedOptionsIds) {
      return false;
    }

    const id = op[this.identifyProperty] as any;
    const selectedOptions = this._selectedOptionsIds ?? [];
    return selectedOptions?.includes(id);
  }

  public hasSelectedIds(): boolean {
    return !!(this._selectedOptionsIds?.length > 0);
  }

  public selectAllOptions(event: any): void {
    const isChecked = event.target.checked;
    this.dropdownClicked = true;

    if (isChecked) {
      const optionsIds = this._options?.map(op => op[this.identifyProperty]) as number[];
      this._selectedOptionsIds = optionsIds;
    } else {
      this._selectedOptionsIds = [];
    }

    this.selectedOptionsIdsChange.emit(this._selectedOptionsIds);
    this.validateMultselectChange();
  }

  public areAllOptionsSelected(): boolean {
    return this._selectedOptionsIds?.length === this._options?.length;
  }

  public listOptions() {
    const sortedOptions = this._options.slice().sort((a, b) => {
      const isSelectedA = this.isSelected(a);
      const isSelectedB = this.isSelected(b);

      if (isSelectedA && !isSelectedB) {
        return -1;
      } else if (!isSelectedA && isSelectedB) {
        return 1;
      } else {
        return 0;
      }
    });

    if (!this.searchText) return sortedOptions;

    return sortedOptions.filter(op => {
      const opProp = op[this.displayProperty] as string;
      return opProp.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
    })
  }

  public validateMultselectChange() {
    this.errorMessage = (this.dropdownClicked && this._selectedOptionsIds.length === 0) ? 'Selecione uma opção' : '';
  }
}
